import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import { Hide } from '@rebass/hide'
import Container from 'components/Container'
import ContactModal from 'components/Modal/ContactModal'
import Section from 'components/Section'
import Twitter from 'components/Icons/Twitter'
import Facebook from 'components/Icons/Facebook'
import LinkedIn from 'components/Icons/LinkedIn'
import styled from 'styled-components'
import media from 'styles/media'
import { H4 } from 'components/Heading'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import {
  FooterSection,
  Impressum,
  FooterContent,
  SocialLinks,
  PageTransitionLinkStyled,
  AddressWrapper,
  AddressDiv,
  AddressLink,
  AddressContainer,
} from './styles'
import CookieInfo from '../../../components/CookieInfo'

const ContainerStyled = styled(Container)`
  position: relative;
  margin: 0 392px;

  ${media.desktop`
    margin: 0 280px;
  `}

  ${media.tablet`
    margin: 0 40px;
  `}

  ${media.phone`
    margin: 0 24px;
    padding: 0;
  `}
`

const Address = ({ address, link, linkText }) => (
  <AddressContainer>
    <AddressDiv dangerouslySetInnerHTML={{ __html: address }} />
    <AddressLink
      style={{ color: 'white' }}
      target="_blank"
      rel="noopener noreferrer"
      href={link}
    >
      {linkText}
    </AddressLink>
    <span style={{ color: 'white' }}>&nbsp; &rarr;</span>
  </AddressContainer>
)

class Footer extends React.Component {
  state = {
    isContactModalOpen: false,
  }

  toggleContactModal = () => {
    this.setState({ isContactModalOpen: !this.state.isContactModalOpen })
  }

  render() {
    const props = this.props
    return (
      <StaticQuery
        query={query}
        render={({
          kenticoCloudItemMasterPage: {
            elements: {
              footer_cta_text,
              contact_cta_title,
              map_link_text,
              berlin_map_link,
              berlin_office,
              copyright,
            },
          },
          kenticoCloudItemSocialLinks: {
            elements: { facebook, linkedin, twitter },
          },
        }) => (
          <>
            <FooterSection {...props}>
              <ContainerStyled height="100%">
                <FooterContent>
                  <div>
                    <H4 whiteText opaque>
                      {footer_cta_text.value}
                    </H4>
                    <H4
                      whiteText
                      onClick={this.toggleContactModal}
                      underline
                      pointer
                    >
                      {contact_cta_title.value}
                    </H4>
                    <SocialLinks>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={twitter.value}
                      >
                        <Twitter size="24px" />
                      </a>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={facebook.value}
                      >
                        <Facebook size="24px" />
                      </a>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={linkedin.value}
                      >
                        <LinkedIn size="24px" />
                      </a>
                    </SocialLinks>
                    <Hide xsmall small>
                      <Impressum>
                        <PageTransitionLinkStyled to={`/impressum`}>
                          Impressum
                        </PageTransitionLinkStyled>
                        <span className="separator">|</span>
                        <PageTransitionLinkStyled to={`/privacy`}>
                          Privacy
                        </PageTransitionLinkStyled>
                        {copyright.value} {new Date().getFullYear()}
                      </Impressum>
                    </Hide>
                  </div>
                  <div>
                    <AddressWrapper>
                      <Section>
                        <Address
                          address={berlin_office.value}
                          link={berlin_map_link.value}
                          linkText={map_link_text.value}
                        />
                      </Section>
                    </AddressWrapper>
                    <Hide medium large xlarge>
                      <Impressum>
                        <PageTransitionLinkStyled to={`/impressum`}>
                          Impressum
                        </PageTransitionLinkStyled>
                        <span className="separator">|</span>
                        <PageTransitionLinkStyled to={`/privacy`}>
                          Privacy
                        </PageTransitionLinkStyled>
                        <span className="copyright">
                          {copyright.value} {new Date().getFullYear()}
                        </span>
                      </Impressum>
                    </Hide>
                  </div>
                </FooterContent>
              </ContainerStyled>
            </FooterSection>
            <TransitionGroup>
              {this.state.isContactModalOpen && (
                <CSSTransition classNames="fade" timeout={300} unmountOnExit>
                  <ContactModal toggleContactModal={this.toggleContactModal} />
                </CSSTransition>
              )}
            </TransitionGroup>
            <CookieInfo />
          </>
        )}
      />
    )
  }
}

Footer.propTypes = {}

Address.propTypes = {
  address: PropTypes.string,
  linkText: PropTypes.string,
  link: PropTypes.string,
}

export default Footer

export const query = graphql`
  {
    kenticoCloudItemMasterPage {
      elements {
        footer_cta_text {
          value
        }
        contact_cta_title {
          value
        }
        copyright {
          value
        }
        map_link_text {
          value
        }
        berlin_map_link {
          value
        }
        berlin_office {
          value
        }
      }
    }
    kenticoCloudItemSocialLinks {
      elements {
        facebook {
          value
        }
        linkedin {
          value
        }
        twitter {
          value
        }
      }
    }
  }
`
