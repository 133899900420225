import styled from 'styled-components'
import Section from './index'
import media from 'styles/media'
import { color } from 'const/colors'

const PageSection = styled(Section)`
  border: ${({ isBorder }) => isBorder && `1px solid ${color.black.o5}`};
  overflow: visible;
  background-color: ${({ bgColor }) => (bgColor ? bgColor : 'transparent')};
  color: ${({ color }) => color};
  padding: ${({ padding }) => padding && '80px 0'};

  ${media.tablet`
    overflow: visible;
    padding: ${({ padding }) => padding && '54px 0'};
  `};
`

export default PageSection
