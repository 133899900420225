import media from 'styles/media'
import Link from 'components/Link'
import Button from 'components/Button'
import Container from 'components/Container'
import styled, { css } from 'styled-components'
import { color } from 'const/colors'
import { animatedLinkStyles } from 'styles/animations'

export const Overlay = styled.div`
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 999;
  overflow: visible;
  overflow-y: scroll;
  top: 0;
  left: 0;
  right: 0;
  padding: 0;
  background-color: ${color.black.o100};

  transition: opacity 0.3s;

  &.fade-enter {
    opacity: 0;
  }

  &.fade-enter-active {
    opacity: 1;
  }

  &.fade-exit {
    opacity: 1;
  }

  &.fade-exit-active {
    opacity: 0;
  }
`

export const ContentContainer = styled.div`
  max-width: 2560px;
  margin: 0 auto;

  ${media.tablet`
    padding: 38px 24px 118px 24px;
  `}

  ${media.phone`
    padding: 38px 24px 118px 0;
  `}
`

export const ContainerStyled = styled(Container)`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  height: calc(100vh - 82px);

  ${media.tablet`
    flex-direction: column;
    align-items: flex-start;
    align-content: flex-start;
    padding: 38px 24px 0;
    margin-top: 50px;
  `}

  ${media.phone`
    margin-bottom: 0;
    padding: 0 0 118px;
  `}
`

export const AddressStyles = css`
  color: ${color.white.o100};
  font-size: 24px;
  line-height: 40px;

  p {
    margin: 0;
  }

  a {
    ${animatedLinkStyles};
    margin-top: 20px;
    display: inline-block;
    line-height: 32px;
  }

  ${media.tablet`
    font-size: 20px;
    line-height: 28px;
  `}
`

export const Close = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: 34px;
  margin: 48px 48px 0 0;
  cursor: pointer;

  & > * {
    display: flex;
    align-self: center;
  }

  & > span {
    padding-left: 5px;
  }

  ${media.tablet`
    margin: 38px 30px 0 0;
  `}

  ${media.phone`
    border: none;

    & span {
      display: none;
    }

    &:hover {
      background-color: ${color.transparent};
    }
  `}
`

export const LinkStyled = styled(Link)`
  margin: 55px 0 0 48px;

  ${media.tablet`
    margin: 32px 0 0 24px;
  `}
`

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  ${media.phone`
    margin-right: 24px;
  `}
`

export const Heading = styled.h1`
  font-size: 44px;
  line-height: 64px;
  color: ${color.white.o100};

  ${media.tablet`
    font-size: 36px;
    line-height: 48px;
  `}
`

export const AddressDiv = styled.div`
  ${({ AddressStyles }) => AddressStyles};
  width: 188px;
  margin-right: 70px;

  /* City */
  p > strong {
    font-size: 24px;
    line-height: 40px;
    font-weight: bold;
    color: ${color.white.o100};
    margin-bottom: 5px;

    ${media.tablet`
      font-size: 20px;
      line-height: 28px;
    `}
  }

  /* Address */
  p:not(:first-child) {
    font-size: 18px;
    line-height: 28px;
    color: ${color.white.o100};

    ${media.tablet`
      font-size: 16px;
      line-height: 20px;
    `}
  }
`

export const AddressLink = styled.a`
  font-size: 18px;
  line-height: 28px;
  padding-bottom: 2px;
  border-color: ${color.white.o100};
  color: ${color.white.o100};
  cursor: pointer;
  display: inline-flex;
  ${animatedLinkStyles};

  ${media.tablet`
    font-size: 16px;
    line-height: 20px;
    margin: 20px 0 40px 0;
  `}
`

export const AddressWrapper = styled.div`
  display: flex;

  ${media.tablet`
    margin-top: 80px;
  `}

  ${media.phone`
    flex-direction: column;
  `}
`
