import React from 'react'
import PropTypes from 'prop-types'
import Logo from 'components/Icons/Logo'
import CloseIcon from 'components/Icons/Close'
import PageTransitionLink from 'components/PageTransitionLink'
import styled from 'styled-components'

import { links } from 'const'
import { color } from 'const/colors'
import {
  Overlay,
  ContainerStyled,
  ContactModalButton,
  Header,
  NavLinks,
  PageTransitionLinkStyledForMobile,
  DistologyModalButton,
} from './styles'

const IconWrapper = styled.div`
  display: flex;
`

class MenuModal extends React.Component {
  render = () => {
    const { toggleMenuModal, switchModals, ...restProps } = this.props
    return (
      <>
        <Overlay {...restProps}>
          <ContainerStyled height="100%" maxWidth="none">
            <Header>
              <PageTransitionLink to="/">
                <Logo width={120} height={30} color={color.white.o100} />
              </PageTransitionLink>
              <IconWrapper>
                <CloseIcon
                  size={24}
                  color={color.white.o100}
                  onClick={toggleMenuModal}
                />
              </IconWrapper>
            </Header>
            <NavLinks>
              <li>
                <PageTransitionLinkStyledForMobile to={links.solutions}>
                  Solutions
                </PageTransitionLinkStyledForMobile>
              </li>
              <li>
                <PageTransitionLinkStyledForMobile to={links.services}>
                  Services
                </PageTransitionLinkStyledForMobile>
              </li>
              <li>
                <PageTransitionLinkStyledForMobile to={links.about}>
                  Team
                </PageTransitionLinkStyledForMobile>
              </li>
              <li>
                <PageTransitionLinkStyledForMobile to={links.careers}>
                  Careers
                </PageTransitionLinkStyledForMobile>
              </li>
              <ContactModalButton onClick={switchModals}>
                Contact
              </ContactModalButton>
              <li>
                <PageTransitionLinkStyledForMobile to={links.blog}>
                  Blog
                </PageTransitionLinkStyledForMobile>
              </li>
              <li>
                <DistologyModalButton
                  href="https://www.distology.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Distology
                </DistologyModalButton>
              </li>
            </NavLinks>
          </ContainerStyled>
        </Overlay>
      </>
    )
  }
}

export default MenuModal

MenuModal.propTypes = {
  toggleMenuModal: PropTypes.func,
  switchModals: PropTypes.func,
}
