import styled from 'styled-components'
import media from 'styles/media'
import { color } from 'const/colors'

const Button = styled.button`
  background-color: ${color.transparent};
  font-size: ${({ fontSize }) => fontSize || '16px'};
  font-weight: 700;
  line-height: 20px;
  color: ${({ color }) => color || `#fff`};
  border: 2px solid
    ${({ secondaryColor }) => secondaryColor || `${color.white.o100}`};
  margin: ${({ margin }) => margin || '0 24px 0'};
  min-width: ${({ btnWidth }) => btnWidth || '78px'};
  padding: ${({ padding }) => (padding ? padding : '16px 25px')};
  cursor: pointer;
  font-family: CeraPro, sans-serif;

  position: relative;
  z-index: 1;
  transition: 0.08s ease-in;
  -o-transition: 0.08s ease-in;
  -ms-transition: 0.08s ease-in;
  -moz-transition: 0.08s ease-in;
  -webkit-transition: 0.08s ease-in;

  @media (hover: hover) and (pointer: fine) {
    &::before {
      content: '';
      position: absolute;
      background: ${({ secondaryColor }) =>
        secondaryColor || `${color.white.o100}`};
      bottom: 0;
      left: 0;
      right: 100%;
      top: 0;
      z-index: -1;
      -webkit-transition: right 0.09s ease-in;
    }

    &:hover:before {
      right: 0;
    }

    &:hover {
      color: ${({ secondaryTextColor }) =>
        secondaryTextColor || `${color.white.o100}`};
    }
  }

  ${media.phone`
    margin-bottom: 50px;
  `}

  &:focus {
    outline: 0;
  }

  &:disabled {
    background-color: ${color.white.o25};
    color: ${color.primary.o100};
  }
`

export default Button
