import styled from 'styled-components'
import { color } from 'const/colors'
import media from 'styles/media'
import { animatedLinkStyles } from 'styles/animations'

export const H4 = styled.h4`
  margin: 0;
  padding: ${({ padding }) => (padding ? padding : 0)};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '20px')};
  line-height: 34px;
  opacity: ${({ opaque }) => opaque && '0.75'};
  color: ${({ whiteText }) =>
    !whiteText ? color.black.o100 : color.white.o100};
  cursor: ${({ pointer }) => pointer && 'pointer'};
  font-weight: normal;

  ${({ underline }) =>
    underline &&
    `
    line-height: 28px;
    ${animatedLinkStyles};
  `};

  ${media.tablet`
    font-size: ${({ tFontSize }) => (tFontSize ? tFontSize : '20px')};
    line-height: 28px;
    padding: 5px 0;
  `}
`

export const P = styled.p`
  color: ${color.black.o100};
  opacity: ${({ opacity }) => (opacity ? opacity : '0.5')};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '18px')};
  line-height: 28px;
  font-weight: 400;
  margin: ${({ margin }) => margin || '0'};
  padding: ${({ padding }) => (padding ? padding : 0)};

  ${media.tablet`
    ${({ mTablet }) => mTablet && `margin: ${mTablet};`}
  `}
`
