import React from 'react'
import PropTypes from 'prop-types'

const Burger = ({ color = '000', size, onClick }) => (
  <div onClick={onClick}>
    <svg width={size} height={size} viewBox="0 0 24 24">
      <defs>
        <path
          d="M0,12 L18,12 L18,10 L0,10 L0,12 Z M0,7 L18,7 L18,5 L0,5 L0,7 Z M0,0 L0,2 L18,2 L18,0 L0,0 Z"
          id="path-1"
        />
      </defs>
      <g
        id="desktop"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="comp-sheet" transform="translate(-784.000000, -1144.000000)">
          <g id="social-icons" transform="translate(752.000000, 1075.000000)">
            <g id="ico/burger" transform="translate(32.000000, 69.000000)">
              <g id="ico-burger" transform="translate(3.000000, 6.000000)">
                <mask id="mask-2" fill="white">
                  <use xlinkHref="#path-1" />
                </mask>
                <use
                  id="Mask"
                  fill={color}
                  fillRule="nonzero"
                  xlinkHref="#path-1"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </div>
)

Burger.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
  onClick: PropTypes.func,
}

export default Burger
