import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import { Hide } from '@rebass/hide'
import Section from 'components/Section'
import Text from 'components/Container/text'
import { color } from 'const/colors'
import CloseIcon from 'components/Icons/Close'
import {
  Overlay,
  ContainerStyled,
  AddressStyles,
  Close,
  Heading,
  AddressDiv,
  AddressLink,
  AddressWrapper,
  ContentContainer,
  Header,
} from './styles'

const Address = ({ address, link, linkText }) => (
  <>
    <AddressDiv dangerouslySetInnerHTML={{ __html: address }} />
    <AddressLink
      style={{ color: `${color.white.o100}` }}
      target="_blank"
      rel="noopener noreferrer"
      href={link}
    >
      {linkText}
    </AddressLink>
    <span style={{ color: `${color.white.o100}` }}>&nbsp; &rarr;</span>
  </>
)

class ContactModal extends React.Component {
  state = {
    closeIconColor: color.white.o100,
  }

  onCloseHover = () => {
    this.setState({ closeIconColor: color.black.o100 })
  }

  onCloseLeave = () => {
    this.setState({ closeIconColor: color.white.o100 })
  }

  render = () => {
    const { toggleContactModal, ...restProps } = this.props
    return (
      <>
        <Overlay {...restProps}>
          <ContentContainer>
            <StaticQuery
              query={query}
              render={({
                kenticoCloudItemMasterPage: {
                  elements: {
                    contact_cta_title,
                    contact_cta_text,
                    map_link_text,
                    berlin_map_link,
                    berlin_office,
                  },
                },
              }) => (
                <>
                  <Header>
                    <Hide xsmall small>
                      <Close
                        onClick={toggleContactModal}
                        color={color.white.o100}
                        padding={'0 15px'}
                        secondaryColor={color.white.o100}
                        secondaryTextColor={color.black.o100}
                        onMouseOver={this.onCloseHover}
                        onMouseLeave={this.onCloseLeave}
                      >
                        <CloseIcon
                          size={12}
                          color={this.state.closeIconColor}
                        />
                        <span>Close</span>
                      </Close>
                    </Hide>
                    <Hide medium large xlarge>
                      <CloseIcon
                        size={20}
                        color={color.white.o100}
                        onClick={toggleContactModal}
                      />
                    </Hide>
                  </Header>
                  <ContainerStyled maxWidth="none">
                    <Section>
                      <Heading>{contact_cta_title.value}</Heading>
                      <Text richText textStyles={AddressStyles}>
                        {contact_cta_text.value}
                      </Text>
                    </Section>
                    <AddressWrapper>
                      <Section>
                        <Address
                          address={berlin_office.value}
                          link={berlin_map_link.value}
                          linkText={map_link_text.value}
                        />
                      </Section>
                    </AddressWrapper>
                  </ContainerStyled>
                </>
              )}
            />
          </ContentContainer>
        </Overlay>
      </>
    )
  }
}

export default ContactModal

ContactModal.propTypes = {
  toggleContactModal: PropTypes.func,
}

Address.propTypes = {
  address: PropTypes.string,
  linkText: PropTypes.string,
  link: PropTypes.string,
}

export const query = graphql`
  {
    kenticoCloudItemMasterPage {
      elements {
        contact_cta_title {
          value
        }
        contact_cta_text {
          value
        }
        map_link_text {
          value
        }
        berlin_map_link {
          value
        }
        berlin_office {
          value
        }
      }
    }
  }
`
