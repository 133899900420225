import { TweenMax, TimelineMax, Power3 } from 'gsap/TweenMax'
import { window } from 'browser-monads'

/****************************** configurable vars ******************************/
var MAX_WIDTH = 2560

let SCALE
let PADDING_X
let iInnerWidth = window.innerWidth
SCALE = iInnerWidth <= 1279 ? 0.71 : 1
if (iInnerWidth <= 767) {
  PADDING_X = 24
} else if (iInnerWidth <= 1279) {
  PADDING_X = 40
} else if (iInnerWidth >= 1280) {
  PADDING_X = 48
}
var PADDING_Y = 32

/****************************** /configurable vars ******************************/

var BAR_SIZE = 40
var LN_SHIFT = 45.8

var svgLogo
var svgDoc
var container
var lines0, lines1, logoPart0, logoPart1, bg, logo
var line00, line01, line10, line11
var _line00, _line01, _line10, _line11

var logoAnim

function init() {
  svgLogo = document.getElementById('svgLogo')
  svgDoc = svgLogo.contentDocument
  container = svgDoc.getElementById('container')

  // console.log('viewBox', container.getAttribute('viewBox'));

  logo = svgDoc.getElementById('logo')
  line00 = svgDoc.getElementById('line00')
  line01 = svgDoc.getElementById('line01')
  line10 = svgDoc.getElementById('line10')
  line11 = svgDoc.getElementById('line11')
  lines0 = [line00, line01]
  lines1 = [line10, line11]
  logoPart0 = svgDoc.getElementById('_part0')
  logoPart1 = svgDoc.getElementById('_part1')
  bg = svgDoc.getElementById('bg')

  _line00 = svgDoc.getElementById('_line00')
  _line01 = svgDoc.getElementById('_line01')
  _line10 = svgDoc.getElementById('_line10')
  _line11 = svgDoc.getElementById('_line11')

  TweenMax.set(bg, { autoAlpha: 0 })

  TweenMax.set([_line00, _line01], { transformOrigin: '0% 0%', scale: SCALE })
  TweenMax.set([_line10, _line11], { transformOrigin: '0% 0%', scale: SCALE })
  TweenMax.set(logo, { transformOrigin: '0% 0%', scale: SCALE })

  TweenMax.set(lines0, { transformOrigin: '0% 0%' })
  TweenMax.set(lines1, { transformOrigin: '0% 0%' })
  TweenMax.set([logoPart0, logoPart1], { autoAlpha: 0 })

  positionElements()
}

function positionElements() {
  TweenMax.set(lines0, {
    x: window.innerWidth,
    scaleX: window.innerWidth / (BAR_SIZE * SCALE),
  })
  TweenMax.set(lines1, {
    y: window.innerHeight,
    scaleY: window.innerHeight / (BAR_SIZE * SCALE),
  })

  var shift =
    window.innerWidth <= MAX_WIDTH ? 0 : (window.innerWidth - MAX_WIDTH) / 2

  TweenMax.set(logo, { x: PADDING_X + shift, y: PADDING_Y })
  TweenMax.set(line00, { y: PADDING_Y })
  TweenMax.set(line01, { y: PADDING_Y + (SCALE - 1) * LN_SHIFT })
  TweenMax.set(line10, { x: PADDING_X + shift })
  TweenMax.set(line11, { x: PADDING_X + shift + (SCALE - 1) * LN_SHIFT })
}

function compileAnim() {
  var w = window.innerWidth
  var h = window.innerHeight

  var scX = w / (BAR_SIZE * SCALE)
  var scY = h / (BAR_SIZE * SCALE)

  var easeIn = Power3.easeIn
  var easeOut = Power3.easeOut

  var timeIn = 1
  var timeOut = 1

  var farAway = 3

  var tl = new TimelineMax({
    paused: true,
  })

  tl.add('start')
    .set(lines0, { x: w, scaleX: scX })
    .set(lines1, { y: h, scaleY: scY })
    .set([logoPart0, logoPart1], { autoAlpha: 0 })

    .add('P1')
    .to(line00, timeIn, { x: 0, scaleX: scX * 0.5, ease: easeIn }, 'P1')
    .to(line10, timeIn, { y: 0, scaleY: scY * 0.5, ease: easeIn }, 'P1')

    .set(logoPart0, { autoAlpha: 1 }, '+=.1')

    .add('P2')
    .to(
      line00,
      timeOut,
      { x: -BAR_SIZE * farAway, scaleX: 0, ease: easeOut },
      'P2'
    )
    .to(
      line10,
      timeOut,
      { y: -BAR_SIZE * farAway, scaleY: 0, ease: easeOut },
      'P2'
    )

    .add('PP1', '-=1.1')
    .to(line01, timeIn, { x: 0, scaleX: scX * 0.5, ease: easeIn }, 'PP1')
    .to(line11, timeIn, { y: 0, scaleY: scY * 0.5, ease: easeIn }, 'PP1')

    .set(logoPart1, { autoAlpha: 1 }, '+=.1')

    .add('PP2')
    .to(
      line01,
      timeOut,
      { x: -BAR_SIZE * farAway, scaleX: 0, ease: easeOut },
      'PP2'
    )
    .to(
      line11,
      timeOut,
      { y: -BAR_SIZE * farAway, scaleY: 0, ease: easeOut },
      'PP2'
    )

    .timeScale(2)
  // .timeScale(.2)

  logoAnim = tl
}

function debounce(func, wait, immediate) {
  var timeout
  return function() {
    var context = this,
      args = arguments
    var later = function() {
      timeout = null
      if (!immediate) func.apply(context, args)
    }
    var callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) func.apply(context, args)
  }
}

function _onResize() {
  var w = window.innerWidth
  var h = window.innerHeight

  // console.log(w, ' x ', h);

  svgLogo.style.width = w
  svgLogo.style.height = h

  bg.setAttribute('width', w)
  bg.setAttribute('height', h)

  container.setAttribute('viewBox', '0 0 ' + w + ' ' + h)

  positionElements()
  compileAnim()
}

var onResize = debounce(_onResize, 300)

export const startAnimation = () => {
  svgLogo = document.getElementById('svgLogo')
  svgLogo.style.opacity = '1'
  init()

  window.addEventListener('resize', onResize)
  _onResize() // resize layer
  svgLogo.style.opacity = '1' // make it visible

  logoAnim.play(0)
}
