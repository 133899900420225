import { color } from 'const/colors'

export const animatedLinkStyles = `
display: inline-block;

&::after {
  content: '';
  display: block;
  width: 100%;
  height: 2px;
  background: ${color.primary.o100};
  margin-top: 4px;
  transition: width .2s;
}

&:hover:after {
  width: 0;
}`
