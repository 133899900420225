import React from 'react'
import PropTypes from 'prop-types'
import Section from 'components/Section'
import Container from 'components/Container'
import Headline from 'components/Headline'
import Tagline from 'components/Tagline'
import Button from 'components/Button'
import { color } from 'const/colors'
import styled from 'styled-components'
import media from 'styles/media'
import PageTransitionLink from 'components/PageTransitionLink'

var TextTransition
var presets

const WrapperSection = styled(Section)`
  
  position: relative;
  
  ${media.desktopLarge`
    padding-bottom: ${({ isColorBackground }) => isColorBackground && '166px'};
  `}

  ${media.desktop`
    padding-bottom: ${({ isColorBackground }) => isColorBackground && '166px'};
  `}

  ${media.tablet`
    padding-bottom: ${({ isColorBackground }) => isColorBackground && '92px'};
  `}

  ${(props) =>
    props.skew
      ? `
    &::after {
    content: '';
    position: absolute;
    background-color: ${color.white.o100};
    bottom: -1px;
    left: 0;
    right: 0;
    height: 5vw;
    max-height: 120px;
    clip-path: polygon(0% 0%, 0 100%, 100% 100%);
   z-index: 10;
  }
  `
      : ''}
`

const ContentSection = styled(Section)`
  padding-top: 100px;
  position: relative;

  
  ${media.desktopLarge`
    max-width: ${({ maxWidth }) => maxWidth || '696px'} ;
  `}
  
  ${media.desktop`
    max-width: ${({ maxWidth }) => maxWidth || '696px'} ;
  `}

  ${media.tablet`
    padding-top: 66px;
    max-width: 465px;
  `}

  ${media.phone`
    width: 100%;
  `}
`
/* z-index: 10; // removed bc it was blocking the .subnav-content */

const HeadlineStyled = styled(Headline)`
  span {
    color: ${color.primary.o100};
    display: inline-block;

    ${media.phone`
      height: 48px;
      display: block;
    `}
  }
`

const HeadlineDescriptionStyled = styled(Headline)`
  margin-top: -30px;
  
  ${media.desktopLarge`
    font-size: 20px;
  `}

  ${media.desktop`
    font-size: 20px;
  `}

  ${media.tablet`
    font-size: 18px;
  `}

  ${media.phone`
    font-size: 18px;
  `}
`

class HeaderSection extends React.Component {
  state = { index: 0 }
  intervalId = 0

  componentDidMount() {
    if (
      typeof window !== `undefined` &&
      window &&
      this.props.transitionedText.length > 0
    ) {
      TextTransition = require('react-text-transition').default
      presets = require('react-text-transition').presets
      this.intervalId = setInterval(() => {
        this.setState({ index: this.state.index + 1 })
      }, 1500)
    }
  }

  componentWillUnmount() {
    clearInterval(this.intervalId)
  }

  render() {
    const {
      headline,
      transitionedText,
      tagline,
      buttonText,
      buttonLink,
      description,
      header = false,
      isColorBackground,
      width,
      maxWidth,
      ...restProps
    } = this.props

    return (
      <WrapperSection isColorBackground={isColorBackground} {...restProps}>
        <Container>
          <ContentSection width={width} maxWidth={maxWidth}>
            {typeof TextTransition !== `undefined` ? (
              <HeadlineStyled
                isColorBackground={isColorBackground}
                header={header}
              >
                {headline}&nbsp;
                <span>
                  <TextTransition
                    noOverflow={true}
                    direction="down"
                    text={
                      transitionedText[
                        this.state.index % transitionedText.length
                      ]
                    }
                    springConfig={presets.default} // eslint-disable-line no-undef
                  />
                </span>
              </HeadlineStyled>
            ) : (
              <HeadlineStyled
                isColorBackground={isColorBackground}
                header={header}
              >
                {headline}
                {/* The next line will prevent the 'jump' on phone layouts if there is transitionedText */}
                {transitionedText.length !== 0 && <span />}
              </HeadlineStyled>
            )}
            {tagline && (
              <Tagline
                isColorBackground={isColorBackground}
                noMb
                richText
                tabletMb="40px"
                {...restProps}
              >
                {tagline}
              </Tagline>
            )}
            {description && (
              <HeadlineDescriptionStyled isColorBackground={isColorBackground}>
                {description}
              </HeadlineDescriptionStyled>
            )}
            {buttonText && (
              <PageTransitionLink to={buttonLink}>
                <Button
                  color={color.white.o100}
                  secondaryColor={color.white.o100}
                  secondaryTextColor={color.primary.o100}
                  margin="0"
                >
                  {buttonText} &rarr;
                </Button>
              </PageTransitionLink>
            )}
          </ContentSection>
        </Container>
      </WrapperSection>
    )
  }
}

export default HeaderSection

HeaderSection.defaultProps = {
  paddingLarge: false,
  transitionedText: [],
}

HeaderSection.propTypes = {
  data: PropTypes.object,
  headline: PropTypes.string,
  tagline: PropTypes.string,
  buttonText: PropTypes.string,
  buttonLink: PropTypes.string,
  width: PropTypes.string,
  maxWidth: PropTypes.string,
  onButtonClick: PropTypes.func,
  header: PropTypes.bool,
  isColorBackground: PropTypes.bool,
  transitionedText: PropTypes.array,
  description: PropTypes.string,
}
