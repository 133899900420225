import React, { Component } from 'react'
import styled from 'styled-components'
import TransitionLink, { TransitionPortal } from 'gatsby-plugin-transition-link'
import { TimelineMax, Power1 } from 'gsap'
import PropTypes from 'prop-types'

const Scrim = styled.div`
  position: fixed;
  background: black;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  -webkit-animation: fadein 0.5s;
  -moz-animation: fadein 0.5s;
  -ms-animation: fadein 0.5s;
  -o-animation: fadein 0.5s;
  animation: fadein 0.5s;

  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 0.2;
    }
  }
`

class PageTransitionLink extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isScrimShow: false,
    }

    this.horizontal = this.horizontal.bind(this)
    this.cover = React.createRef()
  }

  horizontal = ({ props: { length: seconds }, direction }) => {
    const directionTo = direction === 'left' ? '-100%' : '100%'
    const directionFrom = direction === 'left' ? '100%' : '-100%'

    const wait = seconds / 6
    const half = (seconds - wait) / 2

    this.setState({ isScrimShow: true })
    return new TimelineMax()
      .set(this.cover, { y: 0, x: directionFrom, display: 'block' })
      .to(this.cover, half, {
        x: '0%',
        ease: Power1.easeInOut,
      })
      .to(
        this.cover,
        half,
        {
          x: directionTo,
          ease: Power1.easeInOut,
        },
        `+=${wait}`
      )
  }

  moveInDirection = ({ props, direction, node }) => {
    if (direction === 'left' || direction === 'right')
      return this.horizontal({ props, direction, node })
  }

  render() {
    const direction = 'right'
    const length = 0.7
    const isScrimShow = this.state.isScrimShow
    const { ...props } = this.props
    return (
      <>
        <TransitionLink
          style={{ color: this.props.linkcolor || 'unset' }}
          exit={{
            length: length,
            trigger: ({ exit, node }) =>
              this.moveInDirection({
                props: exit,
                node,
                direction,
                dirFade: 'out',
              }),
          }}
          entry={{
            delay: length / 2,
            length: 0,
          }}
          {...props}
        >
          {this.props.children}
        </TransitionLink>

        <TransitionPortal>
          <div
            ref={(n) => (this.cover = n)}
            style={{
              position: 'fixed',
              background: 'transparent',
              top: 0,
              left: 0,
              width: '100vw',
              height: '100vh',
              transform: 'translateY(100%)',
            }}
          />
        </TransitionPortal>
        {isScrimShow && <Scrim isScrimShow={isScrimShow} />}
      </>
    )
  }
}

export default PageTransitionLink

PageTransitionLink.propTypes = {
  children: PropTypes.node.isRequired,
  linkcolor: PropTypes.string,
}
