import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Wrapper = styled.div`
  ${({ textStyles }) => textStyles};
`

const Container = ({ children, richText, textStyles }) => {
  if (richText) {
    return (
      <Wrapper
        textStyles={textStyles}
        dangerouslySetInnerHTML={{ __html: children }}
      />
    )
  } else {
    if (textStyles) {
      return <Wrapper textStyles={textStyles}>{children}</Wrapper>
    }
  }
  return <Wrapper>{children}</Wrapper>
}

Container.propTypes = {
  children: PropTypes.node.isRequired,
  richText: PropTypes.bool,
  textStyles: PropTypes.arrayOf(PropTypes.node),
}

export default Container
