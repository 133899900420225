import React from 'react'
import PropTypes from 'prop-types'
import { Link as GatsbyLink } from 'gatsby'
import styled from 'styled-components'
import { pink } from 'const/colors'
import { animatedLinkStyles } from 'styles/animations'

const Wrapper = styled.div`
  font-size: ${({ size }) => size || '18px'};
  line-height: ${({ lHeight }) => lHeight || 'inherit'};
  border-bottom: ${({ underline }) => (underline ? `2px solid` : 'none')};
  border-color: ${({ lineColor }) => lineColor || pink};
  color: ${({ color }) => color || 'inherit'};
  cursor: pointer;
  display: inline-flex;
  ${({ animatedLink }) => animatedLink && animatedLinkStyles};
`

const Link = ({ to, children, externalLink = false, ...restProps }) => {
  if (externalLink) {
    return (
      <a href={to} target="_blank" rel="noopener noreferrer">
        <Wrapper {...restProps}>{children}</Wrapper>
      </a>
    )
  }

  return (
    <GatsbyLink to={to}>
      <Wrapper {...restProps}>{children}</Wrapper>
    </GatsbyLink>
  )
}

Link.propTypes = {
  children: PropTypes.node,
  to: PropTypes.string,
  externalLink: PropTypes.bool,
}

export default Link
