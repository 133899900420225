import { css } from 'styled-components'
import { breakpoints } from './breakpoints'

/**
 * Iterate through breakpoints creating media query templates for each.
 * Convert min-widths of grid breakpoints to max-widths for large to small
 * CSS, except for lg which is min-width. Example:
 * xs: max-width 47em
 * sm: max-width 64em
 * md: max-width 75em
 * lg: min-width 75em
 * portait: tablets in portrait mode
 *
 * This can be added directly inside styled component like so:
 * import { media } from 'styles';
 * const StyledComponent = styled.div`
 *   ${media.sm`
 *     width: 100%;
 *   `}
 * `;
 *
 * IMPORTANT: when adding media queries in styled components, make sure
 * to place them in descending order of size (lg, md, sm, xs)
 *
 */

const labels = Object.keys(breakpoints)

const media = labels.reduce((acc, label, i) => {
  acc[label] = (...args) => {
    if (label === 'desktopLarge') {
      return css`
        @media (min-width: ${breakpoints[label]}) {
          ${css(...args)};
        }
      `
    }
    return css`
      @media (max-width: ${breakpoints[labels[i + 1]]}) {
        ${css(...args)};
      }
    `
  }
  return acc
}, {})

media.queries = (style, prop = {}) =>
  labels
    .map((point) => ({ name: point, point: breakpoints[point] }))
    .sort((first, second) => second.point - first.point)
    .map(({ name }) => media[name]`${style}: ${prop[name]};`)

export default media
