import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import media from 'styles/media'

const OuterWrapper = styled.div`
  display: flex;
  justify-content: center;
`

const Wrapper = styled.div`
  text-align: ${({ centered }) => (centered ? 'center' : 'left')};
  height: ${({ height }) => height};
  width: 100%;
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : '2560px')};
  position: ${({ position }) => (position ? position : 'static')};
  margin: 0 248px;

  ${media.desktop`
    margin: 0 136px;
  `}

  ${media.tablet`
    margin: 0 24px;
  `}
`

const Container = ({ children, ...restProps }) => (
  <OuterWrapper>
    <Wrapper {...restProps}>{children}</Wrapper>
  </OuterWrapper>
)

Container.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Container
