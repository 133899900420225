import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { createGlobalStyle } from 'styled-components'
import { ThemeProvider } from 'styled-components'
import { StaticQuery, graphql } from 'gatsby'
import Header from './header'
import Footer from './footer'
import JobWrapper from 'components/JobWrapper'
import { color } from 'const/colors'
import styled from 'styled-components'
import SplashScreen from 'components/Splashscreen'
import ScrollToTop from 'react-scroll-to-top'
// import Banner from 'components/Banner'

const ContentContainer = styled.div`
  margin: 0 auto;
  overflow-x: hidden;

  ${(props) => props.currentPath.includes('services') && 'overflow: visible'};

  ${(props) =>
    props.currentPath.includes('careers/listing/')
      ? `background-color: transparent`
      : `background-color: ${color.white.o100}`};
`

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    font-family: 'CeraPro', sans-serif;

    ${(props) =>
      props.currentPath.includes('careers/listing/')
        ? `background-color: ${color.primary.o100}`
        : `background-color: ${color.white.o100}`};

    ${({ isContactFormModalOpen }) =>
      isContactFormModalOpen && `overflow: hidden`};

    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
  }

  a {
    text-decoration: none;
  }

  .tl-edges {
    overflow-x: visible !important;
  }
`

const FooterExtension = styled.div`
  width: 100%;
  height: 298px;
  position: absolute;
  bottom: 0;
  z-index: 0;

  ${(props) =>
    props.currentPath.includes('careers/listing/')
      ? `background-color: ${color.black.o100}`
      : `display: none`};
`

const Wrapper = styled.div`
  position: ${({ currentPath }) =>
    currentPath.includes('careers/listing/') && 'relative'};
`

const theme = {
  space: [0, 6, 12, 18, 24],
  breakpoints: ['768px', '1280px', '1920px'],
}

class Layout extends React.Component {
  state = {
    isSplashscreenActive: false,
    showSplashscreen: false,
    showSplash: false,
    loadFooter: false,
  }

  timeout = null

  prepareSplash() {
    const visitedAlready = sessionStorage.getItem('visitedAlready')
    if (visitedAlready) {
      // I have visited already
      this.setState({ visitedBefore: true })
      this.setState({ isSplashscreenActive: false })
    } else {
      // this is my first visit
      this.setState({ showSplash: true })
      sessionStorage.setItem('visitedAlready', true)

      const pathname = this.props.location.pathname
      if (pathname === '/') {
        setTimeout(() => {
          this.setState({ isSplashscreenActive: false })
        }, 2000)
      } else {
        this.setState({ isSplashscreenActive: false })
      }

      setTimeout(() => {
        this.setState({ showSplashscreen: false })
      }, 4500)
    }
  }

  componentDidMount() {
    // NOTE:
    // Splash Screen will be re-introduced at a later date for A/B testing
    // To enable splashcreen: set initial state for isSplashscreenActive and showSplashscreen to true
    // and uncomment this.prepareSplash() (in componentDidMount()) back in
    // and remove the setTimeout function from componentDidMount and from componentWillUnmount methods.
    //
    // this.prepareSplash()
    this.timeout = setTimeout(() => {
      this.setState({ loadFooter: true })
    }, 250)
  }

  componentWillUnmount() {
    clearTimeout(this.timeout)
  }

  render() {
    const {
      children,
      location,
      isColorBackground,
      headerProps,
      isContactFormModalOpen,
      ogMetadata,
    } = this.props

    const heroAsset =
      ogMetadata && ogMetadata.heroAsset ? ogMetadata.heroAsset : ''
    const serviceItemTitle =
      ogMetadata && ogMetadata.serviceItemTitle
        ? ogMetadata.serviceItemTitle
        : ''
    return (
      <StaticQuery
        query={graphql`
          {
            kenticoCloudItemMasterPage {
              elements {
                page_metadata__opengraph_title {
                  value
                }
                page_metadata__opengraph_image {
                  value {
                    url
                  }
                }
                page_metadata__opengraph_description {
                  value
                }
              }
            }
            allKenticoCloudItemJob {
              edges {
                node {
                  id
                  elements {
                    slug {
                      value
                    }
                  }
                }
              }
            }
          }
        `}
        render={({
          kenticoCloudItemMasterPage: {
            elements: {
              page_metadata__opengraph_title,
              page_metadata__opengraph_description,
              page_metadata__opengraph_image,
            },
          },
          allKenticoCloudItemJob,
        }) => (
          <ThemeProvider theme={theme}>
            <div>
              <GlobalStyle
                isContactFormModalOpen={isContactFormModalOpen}
                currentPath={location.pathname}
              />
              <Helmet
                title={
                  ogMetadata && ogMetadata.title
                    ? ogMetadata.title
                    : page_metadata__opengraph_title.value
                }
                meta={[
                  {
                    property: `og:title`,
                    content: `${
                      ogMetadata && ogMetadata.title && ogMetadata.title !== ''
                        ? ogMetadata.title
                        : page_metadata__opengraph_title.value
                    }`,
                  },
                  {
                    property: `og:image`,
                    content: `${
                      ogMetadata &&
                      ogMetadata.imageUrl &&
                      ogMetadata.imageUrl !== ''
                        ? ogMetadata.imageUrl
                        : page_metadata__opengraph_image.value[0].url
                    }`,
                  },
                  {
                    property: `og:description`,
                    content: `${
                      ogMetadata &&
                      ogMetadata.description &&
                      ogMetadata.description !== ''
                        ? ogMetadata.description
                        : page_metadata__opengraph_description.value
                    }`,
                  },
                ]}
              />
              <JobWrapper
                currentPath={location.pathname}
                currentPaths={allKenticoCloudItemJob}
              >
                <Wrapper currentPath={location.pathname}>
                  {/* <Banner /> */}
                  <ContentContainer currentPath={location.pathname}>
                    {this.state.showSplash &&
                      this.state.showSplashscreen &&
                      location.pathname === '/' && <SplashScreen />}
                    {!this.state.isSplashscreenActive && (
                      <>
                        <Header
                          currentPath={location.pathname}
                          {...headerProps}
                          isColorBackground={isColorBackground}
                          heroAsset={heroAsset}
                          serviceItemTitle={serviceItemTitle}
                        />
                        {children}
                        {this.state.loadFooter && (
                          <Footer bgColor="#391D3e" color="white" />
                        )}
                      </>
                    )}
                  </ContentContainer>
                  <ScrollToTop
                    smooth
                    color={color.hotPink.o100}
                    style={{
                      zIndex: 999,
                    }}
                  />
                  <FooterExtension currentPath={location.pathname} />
                </Wrapper>
              </JobWrapper>
            </div>
          </ThemeProvider>
        )}
      />
    )
  }
}

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  primary: PropTypes.bool,
  location: PropTypes.object,
  isColorBackground: PropTypes.bool,
  headerProps: PropTypes.object,
  isContactFormModalOpen: PropTypes.bool,
  ogMetadata: PropTypes.shape({
    title: PropTypes.string,
    imageUrl: PropTypes.string,
    description: PropTypes.string,
  }),
}

Layout.defaultProps = {
  isColorBackground: false,
}

export default Layout
