import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

export const SvgStyled = styled.svg`
  cursor: ${({ clickable }) => clickable && 'pointer'};
`

const Close = ({ color, size, onClick, clickable }) => (
  <SvgStyled
    onClick={onClick}
    width={size}
    height={size}
    viewBox="0 0 24 24"
    clickable={clickable}
  >
    <path
      d="M9.642 12L.214 2.572 2.572.214 12 9.642 21.428.214l2.358 2.358L14.358 12l9.428 9.428-2.358 2.358L12 14.358l-9.428 9.428-2.358-2.358z"
      fill={color}
      fillRule="evenodd"
    />
  </SvgStyled>
)

Close.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
  onClick: PropTypes.func,
  clickable: PropTypes.bool,
}

export default Close
