export const links = {
  home: '/',
  more: '/more',
  about: '/team',
  services: '/services',
  identityAssessments: '/services/#identity-assessments',
  managedServices: '/services/#managed-services',
  auth0Bootstrap: '/services/#auth0-bootstrap',
  zeroTrustAlliance: '/services/zero-trust-alliance',
  approach: '/what-we-do',
  workspaceIdentity: '/solutions/#workspace-identity',
  consumerIdentity: '/solutions/#consumer-identity',
  engineeringTeams: '/solutions/#engineering-teams',
  solutions: '/solutions',
  careers: '/careers',
  contact: '/contact',
  work: '/work',
  buildTeam: '/how-we-work',
  blog: '/blog',
}
