export const breakpoints = {
  phone: '0',
  tablet: '767px',
  desktop: '1279px',
  desktopLarge: '1919px',
}

export const breakpointsArr = [
  breakpoints.tablet,
  breakpoints.desktop,
  breakpoints.desktopLarge,
]
