import React from 'react'
import PropTypes from 'prop-types'

import SBDistology from '../../assets/sb-logo-distology.inline.svg'
import SBDistologyPink from '../../assets/sb-logo-distology-pink.inline.svg'

const Logo = ({ width, height, color = '#fff', section = 'global' }) => {
  if (
    color === '#fff' ||
    color === 'rgba(255, 255, 255, 1)' ||
    section === 'servicePage'
  ) {
    return <SBDistology width={width} height={height} />
  } else {
    return <SBDistologyPink width={width} height={height} />
  }
}

Logo.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  section: PropTypes.string,
}

export default Logo
