import styled from 'styled-components'
import Section from 'components/Section'
import PageTransitionLink from 'components/PageTransitionLink'
import { animatedLinkStyles } from 'styles/animations'
import media from 'styles/media'
import { color } from 'const/colors'

export const FooterSection = styled(Section)`
  padding: 60px 0 30px 0;
  position: relative;
  bottom: 0;
  z-index: 2;

  ${media.tablet`
    padding: 50px 0 45px 0;
  `}
`

export const Impressum = styled.div`
  font-size: 12px;
  line-height: 20px;
  color: #fff;
  opacity: 0.75;
  padding-top: 15px;

  span.separator {
    padding-left: 4px;
    padding-right: 4px;
  }

  span.copyright {
    white-space: nowrap;
  }

  ${media.tablet`
    font-size: 14px;
  `}
`

export const FooterContent = styled.div`
  display: flex;
  justify-content: space-between;

  ${media.tablet`
    flex-direction: column;
    align-items: flex-start;
  `}
`

export const AddressWrapper = styled.div`
  display: flex;

  ${media.tablet`
    flex-direction: column;
    margin-top: 60px;
  `}
`

export const SocialLinks = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 28px 0 50px 0;
  width: 106px;
  justify-content: space-between;

  ${media.tablet`
    padding: 46px 0 0 0;
  `}
`

export const PageTransitionLinkStyled = styled(PageTransitionLink)`
  padding-right: 14px;

  & :first-child {
    padding-right: 0;
  }
`

export const AddressDiv = styled.div`
  color: ${color.white.o75};

  p:not(:first-child) {
    font-size: 14px;
    line-height: 20px;
    margin: 0;
  }

  p:first-child {
    margin: 9px 0 13px 0;
    font-size: 16px;
    line-height: 20px;
    font-weight: bold;
  }

  p:nth-child(2) {
    display: none;
  }
`

export const AddressLink = styled.a`
  font-size: 14px;
  line-height: 20px;
  padding: 10px 0 2px 0;
  color: ${color.white.o100};
  cursor: pointer;
  display: inline-flex;
  ${animatedLinkStyles};

  ${media.tablet`
    font-size: 16px;
    margin-bottom: 40px;
  `}
`

export const AddressContainer = styled.div`
  margin-left: 70px;

  ${media.tablet`
    margin-left: 0;
  `}
`
