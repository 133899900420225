import React from 'react'
import PropTypes from 'prop-types'

const LinkedIn = ({ color = '#fff', size }) => (
  <svg width={size} height={size} viewBox="0 0 24 24">
    <g
      id="desktop"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      opacity="0.5"
    >
      <g
        id="comp-sheet"
        transform="translate(-656.000000, -1320.000000)"
        fill={color}
        fillRule="nonzero"
      >
        <g id="social-icons" transform="translate(544.000000, 1251.000000)">
          <g id="ico/linkedin" transform="translate(112.000000, 69.000000)">
            <g id="linkedin">
              <path
                d="M22,21.5575 L17.7125,21.5575 L17.7125,14.6575 C17.7125,12.925 17.0925,11.74 15.54,11.74 C14.3575,11.74 13.65,12.54125 13.34,13.31 C13.22625,13.58625 13.19875,13.9675 13.19875,14.355 L13.19875,21.5575 L8.9125,21.5575 C8.9125,21.5575 8.9675,9.87 8.9125,8.6625 L13.19875,8.6625 L13.19875,10.48875 C13.77,9.60625 14.7875,8.35875 17.06375,8.35875 C19.88375,8.35875 22,10.2 22,14.1625 L22,21.5575 Z M2.2525,21.5575 L2.2525,8.6625 L6.54125,8.6625 L6.54125,21.5575 L2.2525,21.5575 Z M4.39625,6.89875 L4.36875,6.89875 C2.93,6.89875 2,5.9075 2,4.67 C2,3.40625 2.96,2.44125 4.42375,2.44125 C5.89125,2.44125 6.795,3.40625 6.82125,4.67 C6.82125,5.9075 5.89125,6.89875 4.39625,6.89875 Z"
                id="Combined-Shape"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

LinkedIn.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
}

export default LinkedIn
