import React, { useState } from 'react'
import { CookieInfoWrapper } from './styles'
import CloseIcon from 'components/Icons/Close'

const CookieInfo = () => {
  const [cookieVisible, setCookieVisible] = useState(
    localStorage.getItem('hide-cookie-info') !== 'true'
  )

  if (!cookieVisible) return null

  return (
    <CookieInfoWrapper>
      <p>
        Our website uses only technically necessary cookies. For more
        information visit our&nbsp;<a href="/privacy">privacy policy</a>.
      </p>
      <CloseIcon
        size={16}
        color="#fff"
        onClick={() => {
          setCookieVisible(false)
          localStorage.setItem('hide-cookie-info', 'true')
          document.cookie = 'marketing-cookie-allowed=true'
        }}
      />
    </CookieInfoWrapper>
  )
}

export default CookieInfo
