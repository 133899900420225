import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Hide } from '@rebass/hide'
import Logo from 'components/Icons/Logo'
import Burger from 'components/Icons/Burger'
import media from 'styles/media'
import { links } from 'const'
import { color } from 'const/colors'
import PageTransitionLink from 'components/PageTransitionLink'
import ContactModal from 'components/Modal/ContactModal'
import MenuModal from 'components/Modal/MenuModal'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import * as KontentDelivery from '@kontent-ai/delivery-sdk'
import './header.css'

const isValidServiceUrl = (url) => {
  const regex = /\/services\/.+$/
  return regex.test(url)
}

// const isRootOrOneRoute = (path) => {
//   const rootRegex = /^\/$/
//   // eslint-disable-next-line no-useless-escape
//   const moreThanOneRouteRegex = /^\/[^\/]+\/[^\/]+/

//   return rootRegex.test(path) || moreThanOneRouteRegex.test(path)
// }

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ isColorBackground }) =>
    isColorBackground && color.primary.o100};
  color: ${color.black.o100};
`

const InnerWrapper = styled.div`
  width: 100%;
  max-width: 2560px;
  height: ${({ currentPath }) =>
    isValidServiceUrl(currentPath) ? '400px;' : '100%;'}
  padding: ${({ currentPath }) =>
    isValidServiceUrl(currentPath) ? '70px 48px 0 48px;' : '55px 48px 0 48px;'} 
  overflow: visible;
  display: flex;
  justify-content: space-between;
  align-items: ${({ currentPath }) =>
    isValidServiceUrl(currentPath) ? 'start;' : 'unset;'}
  background-image: ${({ currentPath, heroAsset }) =>
    isValidServiceUrl(currentPath) ? `url(${heroAsset});` : 'unset;'}
  background-size:  ${({ currentPath }) =>
    isValidServiceUrl(currentPath) ? 'cover;' : 'unset;'}
  background-repeat: ${({ currentPath }) =>
    isValidServiceUrl(currentPath) ? 'no-repeat;' : 'unset;'}
  background-position:   background-repeat: ${({ currentPath }) =>
    isValidServiceUrl(currentPath) ? 'center;' : 'unset;'}

  position: relative;
  z-index: 10;

  a {
    color: #391D3e;
  }

  ${media.tablet`
    padding: 32px 24px 0 24px;
  `}
`

const HeaderLinks = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  flex-grow: 1;

  position: relative;
  z-index: 10;

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    overflow: hidden;
    display: flex;
  }

  li {
    padding-left: 25px;
  }
`

const FlexHeroAssetTitleArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  position: absolute;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 32px;
  color: white;
  z-index: -1;

  ${media.phone`
    width: fit-content;
  `}
`

const SubmenuLink = styled.a`
  color: #391d3e;
  z-index: 222;
  opacity: 1;
  &:hover {
    color: #ed1164;
    opacity: 1;
  }
`

// const ContactModalLink = styled.div`
//   color: ${({ isColorBackground }) => isColorBackground && color.white.o100};
//   font-size: 16px;
//   font-weight: bold;
//   line-height: 20px;
//   cursor: pointer;
//   display: inline-block;

//   &::after {
//     content: '';
//     display: block;
//     width: 0;
//     height: 2px;
//     background: ${({ isColorBackground }) =>
//       isColorBackground ? color.white.o100 : color.primary.o100};
//     margin-top: 1px;
//     transition: width 0.2s;
//   }

//   &:hover:after {
//     width: 100%;
//   }

//   ${({ underline }) => underline && `&::after { width: 100%; }`};
// `

export const StyledTransLink = styled.div`
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
  color: ${({ isColorBackground }) => isColorBackground && color.white.o100};
  cursor: pointer;
  display: inline-block;

  &::after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: ${({ isColorBackground }) =>
      isColorBackground ? color.white.o100 : color.primary.o100};
    margin-top: 1px;
    transition: width 0.2s;
  }

  &:hover:after {
    width: 100%;
  }

  ${({ underline }) => underline && `&::after { width: 100%; }`};
`

class Header extends React.Component {
  state = {
    isContactModalOpen: false,
    isContactFormModalOpen: false,
    isMenuModalOpen: false,
    navbarItems: [],
  }

  handleGetNavigationItems = async () => {
    const deliveryClient = KontentDelivery.createDeliveryClient({
      projectId: '912cbcdf-d759-008d-4d77-11169659c9e6',
    })
    // Gets navigation items and their linked items
    try {
      const rootResponse = await deliveryClient
        .item('navigation_menu')
        .depthParameter(5)
        .toPromise()

      if (rootResponse.data && rootResponse.data.item) {
        this.setState({
          navbarItems:
            rootResponse.data.item.elements.navigationitems.linkedItems,
        })
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
    }
  }

  componentDidMount() {
    this.handleGetNavigationItems()
  }

  toggleContactModal = () => {
    this.setState({ isContactModalOpen: !this.state.isContactModalOpen })
  }

  toggleMenuModal = () => {
    this.setState({ isMenuModalOpen: !this.state.isMenuModalOpen })
  }

  switchModals = () => {
    this.setState({
      isMenuModalOpen: !this.state.isMenuModalOpen,
      isContactModalOpen: !this.state.isContactModalOpen,
    })
  }

  render = () => {
    const {
      isColorBackground,
      burgerColor,
      currentPath,
      heroAsset,
      serviceItemTitle,
    } = this.props
    const { navbarItems } = this.state
    if (navbarItems.length === 0) {
      return <div>Loading...</div>
    }
    const isActiveLink = (link) => {
      if (link === '/') {
        return currentPath === link
      } else return currentPath.includes(link)
    }
    return (
      <div>
        <Wrapper {...this.props}>
          <InnerWrapper {...this.props}>
            <div>
              <PageTransitionLink to={'/'}>
                <Hide xsmall small>
                  <Logo
                    width={320}
                    height={60}
                    color={
                      isColorBackground ? color.white.o100 : color.primary.o100
                    }
                    section={
                      isValidServiceUrl(currentPath) ? 'servicePage' : 'global'
                    }
                  />
                </Hide>
                <Hide medium large xlarge>
                  <Logo
                    width={120}
                    height={30}
                    color={
                      isColorBackground ? color.white.o100 : color.primary.o100
                    }
                    section={
                      isValidServiceUrl(currentPath) ? 'servicePage' : 'global'
                    }
                  />
                </Hide>
              </PageTransitionLink>
            </div>
            <Hide xsmall small>
              <HeaderLinks>
                <ul className="navbar">
                  {navbarItems.length > 0 &&
                    navbarItems.map((navbarItem) => {
                      {
                        return navbarItem.elements.has_submenu.value ===
                          'Yes' ? (
                          <li className="subnav" key={navbarItem.system.id}>
                            <PageTransitionLink
                              to={navbarItem.elements.link_to.value}
                              linkcolor={
                                isValidServiceUrl(currentPath)
                                  ? 'white'
                                  : 'black'
                              }
                            >
                              <StyledTransLink
                                {...this.props}
                                underline={isActiveLink(
                                  navbarItem.elements.title.value ===
                                    'Solutions'
                                    ? links.solutions
                                    : links.services
                                )}
                              >
                                {navbarItem.elements.title.value}
                              </StyledTransLink>
                            </PageTransitionLink>
                            <div
                              className="subnav-content"
                              id={`${navbarItem.elements.title.value}`}
                            >
                              {navbarItem.elements.navigationitems.linkedItems.map(
                                (subItem) => {
                                  return (
                                    <div
                                      className="subnav-item"
                                      key={subItem.system.id}
                                    >
                                      <SubmenuLink
                                        href={`${subItem.elements.link_to.value}`}
                                      >
                                        {subItem.elements.title.value}
                                      </SubmenuLink>
                                    </div>
                                  )
                                }
                              )}
                            </div>
                          </li>
                        ) : navbarItem.elements.link_to.value === '/contact' ? (
                          <li
                            key={navbarItem.system.id}
                            style={{
                              height: 30,
                              color:
                                isValidServiceUrl(currentPath) ||
                                currentPath === '/'
                                  ? 'white'
                                  : 'black',
                              cursor: 'pointer',
                              fontWeight: 'bold',
                            }}
                            onClick={this.toggleContactModal}
                          >
                            {navbarItem.elements.title.value}
                          </li>
                        ) : (
                          <li key={navbarItem.system.id} style={{ height: 30 }}>
                            <PageTransitionLink
                              to={navbarItem.elements.link_to.value}
                              linkcolor={
                                isValidServiceUrl(currentPath)
                                  ? 'white'
                                  : 'black'
                              }
                            >
                              <StyledTransLink
                                {...this.props}
                                underline={isActiveLink(
                                  navbarItem.elements.link_to.value
                                )}
                              >
                                {navbarItem.elements.title.value}
                              </StyledTransLink>
                            </PageTransitionLink>
                          </li>
                        )
                      }
                    })}

                  <li>
                    <a
                      href="https://www.distology.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        color: isValidServiceUrl(currentPath)
                          ? 'white'
                          : 'unset',
                      }}
                    >
                      <StyledTransLink {...this.props}>
                        Distology
                      </StyledTransLink>
                    </a>
                  </li>
                </ul>
              </HeaderLinks>
            </Hide>
            <Hide medium large xlarge>
              <Burger
                onClick={this.toggleMenuModal}
                color={isValidServiceUrl(currentPath) ? 'white' : burgerColor}
                size="32px"
              />
            </Hide>
            {isValidServiceUrl(currentPath) && serviceItemTitle && heroAsset && (
              <>
                <FlexHeroAssetTitleArea>
                  <h2>{serviceItemTitle}</h2>
                </FlexHeroAssetTitleArea>
              </>
            )}
          </InnerWrapper>
        </Wrapper>
        <TransitionGroup>
          {this.state.isContactModalOpen && (
            <CSSTransition classNames="fade" timeout={300} unmountOnExit>
              <ContactModal toggleContactModal={this.toggleContactModal} />
            </CSSTransition>
          )}
        </TransitionGroup>
        <TransitionGroup>
          {this.state.isMenuModalOpen && (
            <CSSTransition classNames="fade" timeout={300} unmountOnExit>
              <MenuModal
                toggleMenuModal={this.toggleMenuModal}
                switchModals={this.switchModals}
              />
            </CSSTransition>
          )}
        </TransitionGroup>
      </div>
    )
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
  currentPath: PropTypes.string,
  serviceItemTitle: PropTypes.string,
  heroAsset: PropTypes.string,
  logoColor: PropTypes.string,
  burgerColor: PropTypes.string,
  isColorBackground: PropTypes.bool,
}

Header.defaultProps = {
  logoColor: null,
  burgerColor: 'black',
}

export default Header
