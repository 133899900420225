import styled from 'styled-components'
import media from 'styles/media'

export const CookieInfoWrapper = styled.div`
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #391d3e;
  position: fixed;
  right: auto;
  left: 50%;
  bottom: 16px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: auto;
  height: auto;
  min-height: 32px;
  padding: 0 20px;
  z-index: 1099;
  border-radius: 10px;
  font-size: 12px;

  a {
    display: inline;
    color: #fff;

    :hover {
      color: #821c5a;
    }
  }

  p {
    padding-right: 20px;
    white-space: nowrap;

    ${media.phone`
      white-space: normal;
    `}
  }

  svg {
    position: absolute;
    right: 15px;
    cursor: pointer;
  }

  ${media.phone`
      width: 90%;
      padding: 5px;
  `}
`
