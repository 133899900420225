import React, { Component } from 'react'
import styled from 'styled-components'
import { startAnimation } from './logoAnimation'
import media from 'styles/media'

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  padding: 0;
  overflow-y: hidden;
  overflow: hidden;
  -webkit-transition: top 0.4s ease;
  ${({ slideUp }) => slideUp && `top: -110vh;`}

  background: linear-gradient(89deg, #f93b4c, #f94580, #f95e3b);
  background-size: 400% 400%;

  -webkit-animation: AnimationName 3s ease infinite;
  -moz-animation: AnimationName 3s ease infinite;
  animation: AnimationName 3s ease infinite;

  @-webkit-keyframes AnimationName {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  @-moz-keyframes AnimationName {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  @keyframes AnimationName {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`

const TextWrapper = styled.div`
  height: 100vh;
  width: 100vw;
  max-width: 2560px;
  position: fixed;
  top: 0px;
  transition: top .4s ease;
  -webkit-transition: top .4s ease;
  ${({ slideUp }) => slideUp && `top: -150vh;`}
  color: white;
  font-weight: bold;
  padding-top: 190px;
  padding-left: 136px;
  font-size: 44px;
  line-height: 64px;

  ${media.desktopLarge`
    margin-left: calc((100vw - 2560px) / 2);
    margin-right: calc((100vw - 2560px) / 2);
    padding-left: 248px;
  `}

  ${media.tablet`
    padding-top: 137px;
    padding-left: 100px;
    font-size: 36px;
    line-height: 54px;
  `}

  ${media.phone`
    padding: 137px 20px 0 75px;
    font-size: 36px;
    line-height: 54px;
  `}
`

const Text = styled.div`
  -webkit-transition: top 0.4s ease;
  ${({ slideUp }) => slideUp && `top: -110vh;`}

  -webkit-animation: fadein 3s;
  -moz-animation: fadein 3s;
  -ms-animation: fadein 3s;
  -o-animation: fadein 3s;
  animation: fadein 3s;

  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  ${media.phone`
    padding-bottom: 10px;
    width: 80%;
  `}
`

class SplashScreen extends Component {
  state = {
    animateSlideUp: false,
    showTextTwo: false,
    showTextWrapper: false,
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ animateSlideUp: true })
    }, 4000)

    setTimeout(() => {
      this.setState({ showTextTwo: true })
    }, 1400)

    setTimeout(() => {
      this.setState({ showTextWrapper: true })
    }, 1000)
  }

  render() {
    return (
      <Wrapper slideUp={this.state.animateSlideUp}>
        <object
          onLoad={startAnimation}
          id="svgLogo"
          data="images/white_logo.svg"
          type="image/svg+xml"
          style={{ opacity: 0 }}
        ></object>
        {this.state.showTextWrapper && (
          <TextWrapper slideUp={this.state.animateSlideUp}>
            <Text slideUp={this.state.animateSlideUp}>
              Hello we&apos;re Distology Studios
            </Text>
            {this.state.showTextTwo && (
              <Text slideUp={this.state.animateSlideUp}>
                Your full service product studio
              </Text>
            )}
          </TextWrapper>
        )}
      </Wrapper>
    )
  }
}

export default SplashScreen

SplashScreen.propTypes = {}
