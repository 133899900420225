import React from 'react'
import PropTypes from 'prop-types'
import TextContainer from 'components/Container/text'
import styled, { css } from 'styled-components'
import { color } from 'const/colors'
import media from 'styles/media'

const Wrapper = styled.div`
  opacity: ${({ taglineOpacity }) => taglineOpacity || 0.5};
  font-size: 24px;
  line-height: 40px;
  max-width: ${({ maxWidth }) => maxWidth};
  margin-bottom: ${({ noMb }) => (noMb ? '0' : '108px')};
  padding: ${({ padding }) => padding};

  ${media.desktopLarge`
    font-size: 24px;
    line-height: 40px;

    ${({ isColorBackground, taglineColor }) =>
      isColorBackground &&
      css`
        opacity: 1;
        color: ${taglineColor || color.white.o100};
        font-size: 44px;
        font-weight: bold;
        letter-spacing: 0.7px;
        margin-top: -44px;
        line-height: 64px;
      `};
  `}

  ${media.desktop`
    font-size: 24px;
    line-height: 40px;

    ${({ isColorBackground, taglineColor }) =>
      isColorBackground &&
      css`
        opacity: 1;
        color: ${taglineColor || color.white.o100};
        font-size: 44px;
        font-weight: bold;
        letter-spacing: 0.7px;
        margin-top: -44px;
        line-height: 64px;
      `};
  `}

  ${media.tablet`
    font-size: 20px;
    line-height: 28px;
    margin-bottom: ${({ tabletMb }) => (!tabletMb ? '65px' : tabletMb)};

    ${({ isColorBackground }) =>
      isColorBackground &&
      css`
        color: ${color.white.o100};
        font-size: 36px;
        letter-spacing: 0.5px;
        margin-top: -28px;
        line-height: 48px;
      `};
  `}

  ${media.phone`
    margin-bottom: 25px;
  `}
`

const Tagline = ({ children, ...restProps }) => {
  return (
    <Wrapper {...restProps}>
      <TextContainer {...restProps}>{children}</TextContainer>
    </Wrapper>
  )
}

Tagline.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
}

export default Tagline
