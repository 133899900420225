import React from 'react'
import PropTypes from 'prop-types'
import TextContainer from 'components/Container/text'
import styled from 'styled-components'
import { color } from 'const/colors'
import media from 'styles/media'

const Wrapper = styled.div`
  color: ${(props) => (props.color ? props.color : 'inherit')};
  font-weight: bold;
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? marginBottom : '20px'};
  line-height: ${(props) => (props.fontSize ? props.fontSize : '64px')};

  ${media.desktopLarge`
    ${({ isColorBackground }) =>
      isColorBackground
        ? `
      color: ${color.white.o50};
      font-weight: normal;
      font-size: 24px;
      line-height: 40px;
    `
        : `
      opacity: 95%;
      font-size: 44px;
    `};
  `}
  
  ${media.desktop`
    font-size: ${({ header }) => (header ? '44px' : '36px')};
    line-height: ${({ header }) => (header ? '64px' : '48px')};

    ${({ isColorBackground }) =>
      isColorBackground &&
      `
      color: ${color.white.o50};
      font-weight: normal;
      font-size: 24px;
      line-height: 40px;
    `};
  `}
  
  ${media.tablet`
    font-size: ${({ header }) => (header ? '36px' : '24px')};
    line-height: ${({ header }) => (header ? '48px' : '40px')};
    
     ${({ tMarginBottom }) =>
       tMarginBottom && `margin-bottom:${tMarginBottom}`};

    ${({ isColorBackground }) =>
      isColorBackground &&
      `
      font-size: 20px;
      line-height: 28px;
    `};
  `}
`

const Section = (props) => {
  return (
    <Wrapper {...props}>
      <TextContainer>{props.children}</TextContainer>
    </Wrapper>
  )
}

Section.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Section
