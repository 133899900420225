import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Box } from '@rebass/grid'

const StyledBox = styled(Box)`
  ${({ width }) => (width != 1 ? 'float:left' : null)};
  background: ${({ bgColor }) => bgColor};
  background-color: ${({ bgColor }) => (bgColor ? bgColor : 'inherit')};
  background-image: ${({ bgImage }) => (bgImage ? bgImage : 'none')};
  color: ${({ color }) => (color ? color : 'inherit')};
  transition: all 0.2s;

  a {
    color: ${({ color }) => (color ? color : 'inherit')};
  }

  &:hover {
    background-color: ${({ hbgColor }) => hbgColor};
  }
`

const Section = ({
  children,
  className,
  bgColor,
  hbgColor,
  bgImage,
  color,
  width,
  padding,
  ...restProps
}) => {
  let boxWidth = width
  if (!boxWidth) boxWidth = 1
  return (
    <StyledBox
      pd={padding}
      className={className}
      width={boxWidth}
      bgColor={bgColor}
      hbgColor={hbgColor}
      bgImage={bgImage}
      color={color}
      {...restProps}
    >
      {children}
    </StyledBox>
  )
}

Section.propTypes = {
  children: PropTypes.node.isRequired,
  bgColor: PropTypes.string,
  bgImage: PropTypes.string,
  color: PropTypes.string,
  width: PropTypes.node,
  className: PropTypes.string,
  padding: PropTypes.bool,
  hbgColor: PropTypes.string,
}

Section.defaultProps = {
  padding: false,
}

export default Section
