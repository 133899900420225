import React from 'react'
import PropTypes from 'prop-types'

const Twitter = ({ color = '#fff', size }) => (
  <svg
    height={size}
    viewBox="0 0 24 24"
    width={size}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m19.964875 9.452875c0 5.4225-4.1275 11.675-11.675 11.675-2.31875 0-4.47375-.67875-6.29-1.84375.3225.03625.6475.05625.97875.05625 1.92375 0 3.69125-.65375 5.095-1.755-1.79375-.035-3.31-1.22125-3.83125-2.85.25.04625.50625.07375.77125.07375.375 0 .7375-.05125 1.08-.14375-1.875-.37875-3.29125-2.03625-3.29125-4.02375v-.05125c.555.3075 1.18625.49125 1.86.5125-1.10125-.735-1.825-1.9925-1.825-3.415 0-.7525.2025-1.4575.555-2.06375 2.0225 2.48375 5.0475 4.11625 8.4575 4.2875-.07-.3-.10625-.6125-.10625-.935 0-2.26625 1.83625-4.10125 4.10375-4.10125 1.17875 0 2.24625.4975 2.99375 1.29375.93375-.18375 1.81375-.525 2.60625-.99625-.3075.96-.95625 1.7625-1.80375 2.27.83-.0975 1.62125-.31875 2.35625-.64375-.55.8225-1.245 1.545-2.0475 2.12375.00875.17625.0125.35125.0125.53z"
      fill={color}
      opacity=".5"
    />
  </svg>
)

Twitter.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
}

export default Twitter
