import Container from 'components/Container'
import media from 'styles/media'
import styled from 'styled-components'
import { white, black } from 'const/colors'
import PageTransitionLink from 'components/PageTransitionLink'

export const Overlay = styled.div`
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 999;
  overflow: visible;
  overflow-y: scroll;
  top: 0;
  left: 0;
  right: 0;
  padding: 0;
  background-color: ${black};

  transition: opacity 0.3s;

  &.fade-enter {
    opacity: 0;
  }

  &.fade-enter-active {
    opacity: 1;
  }

  &.fade-exit {
    opacity: 1;
  }

  &.fade-exit-active {
    opacity: 0;
  }
`

export const ContainerStyled = styled(Container)`
  display: flex;
  align-items: left;
  flex-direction: column;

  ul > li {
    list-style-type: none;
    line-height: 80px;
  }
`

export const ContactModalButton = styled.li`
  font-size: 44px;
  line-height: 60px;
  cursor: pointer;
  color: white;
`

export const DistologyModalButton = styled.a`
  font-size: 44px;
  line-height: 60px;
  cursor: pointer;
  color: white;
`

export const Header = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 32px;
`

export const NavLinks = styled.ul`
  padding: 0;
  margin-top: 100px;

  ${media.phone`
    margin: 47px 0 145px 0;
  `}
`

export const PageTransitionLinkStyled = styled(PageTransitionLink)`
  font-size: 44px;
  color: ${white};
`

export const PageTransitionLinkStyledForMobile = styled(PageTransitionLink)`
  font-size: 44px;
  color: #fff !important;
`
