import React from 'react'
import PropTypes from 'prop-types'

const Facebook = ({ color = '#fff', size }) => (
  <svg width={size} height={size} viewBox="0 0 24 24">
    <g
      id="desktop"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      opacity="0.5"
    >
      <g
        id="comp-sheet"
        transform="translate(-576.000000, -1320.000000)"
        fill={color}
        fillRule="nonzero"
      >
        <g id="social-icons" transform="translate(544.000000, 1251.000000)">
          <g id="ico/facebook" transform="translate(32.000000, 69.000000)">
            <g id="facebook">
              <path
                d="M16.28475,12.007375 L13.48225,12.007375 L13.48225,21.999875 L9.32975,21.999875 L9.32975,12.007375 L7.3535,12.007375 L7.3535,8.477375 L9.32975,8.477375 L9.32975,6.192375 C9.32975,4.556125 10.106,1.999875 13.51975,1.999875 L16.596,2.012375 L16.596,5.439875 L14.3635,5.439875 C13.99975,5.439875 13.48225,5.621125 13.48225,6.402375 L13.48225,8.477375 L16.6485,8.477375 L16.28475,12.007375 Z"
                id="Combined-Shape"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

Facebook.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
}

export default Facebook
